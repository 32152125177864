<template>
    <ArticleCardContent v-editable="blok" :data="articleCardContentData" />
</template>

<script setup lang="ts">
import ButtonType from '~/types/ButtonType';
import ArticleCardContentType from '~/types/ArticleCardContentType';
import ImageType from '~/types/ImageType';
import ArticleCardType from '~/types/ArticleCardType';
import ArticleCardContent from '~/components/page-building/ArticleCardContent.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const articleCardContentData = computed<ArticleCardContentType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const cards = props.blok.cards
        ? props.blok.cards.map((card: any) => {
              return {
                  image: {
                      title: card.content.image.title,
                      alt: card.content.image.alt,
                      src: card.content.image.filename,
                  } as ImageType,
                  categories: card.content.categories,
                  title: card.content.title,
                  date: card.content.date,
                  shortDescription: card.content.short_description
                      ? (richTextToHtml(
                            card.content.short_description,
                        ) as string)
                      : '',
                  description: card.content.description
                      ? (richTextToHtml(card.content.description) as string)
                      : '',
                  slug: '/' + card.full_slug,
                  vEditable: card,
              } as ArticleCardType;
          })
        : [];

    return {
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        buttons,
        cards,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
